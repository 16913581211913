<template>
	<section v-if="showPopup" class="modal showing j-upsell-popup j-popup">
		<div class="modal__card modal__card--programm">
			<button
				class="modal__close remove-btn remove-btn--white j-upd-additional-info j-hide-popup"
				type="button"
				:data-url="upd_additional_info_path"
				:data-additional-info="`upsell-${current_company_url}-no`"
			>
				<span class="visually-hidden">{{ $t('buttons.close') }}</span>
			</button>
			<div class="modal__image-holder">
				<img src="../../../static/images/maraphones/upsales.png" width="100%" alt="Переходите на следующий уровень">
			</div>
			<div class="modal__main">
				<div v-html="text"></div>
				<div class="mt-20 text-center">
					<button
						class="large-btn action-btn j-upd-additional-info j-hide-popup"
						type="button"
						@click="sendUpsell"
						:data-url="upd_additional_info_path"
						:data-additional-info="`upsell-${current_company_url}-yes`"
					>
						Рассчитать стоимость
					</button>
				</div>
			</div>
		</div>
	</section>
	<section v-show="showTwoPart" class="modal j-upsell-two-part-popup j-popup">
		<div class="modal__card modal__card--small">
			<button
				class="modal__close remove-btn remove-btn--white j-hide-popup"
				type="button"
			>
				<span class="visually-hidden">{{ $t('buttons.close') }}</span>
			</button>
			<div class="modal__main">
				<p class="modal__subtitle modal__subtitle--small text-center">Ваш запрос отправлен!</p>
				<p class="modal__text modal__text--large text-center">Наш менеджер с вами свяжется</p>
				<div class="mt-20 text-center">
					<button class="regular-btn action-btn j-hide-popup" type="button">
						Закрыть
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import upsellPopups from '../../common/upsell-popups.js';
import axios from "axios";

export default {
	name: 'UpsellPopup',

	props: {
		current_company_url: {
			type: String,
			default: '',
		},
		current_company_name: {
			type: String,
			default: '',
		},
		progress: {
			type: Number,
			default: 30,
		},
		events_path: {
			type: String,
			default: ''
		},
		upd_additional_info_path: {
			type: String,
			default: ''
		}
	},

	data() {
		return {
			showPopup: false,
			text: '',
			description: '',
			showTwoPart: false
		}
	},

	created() {
		upsellPopups.forEach((popup) => {
			if (popup.url === this.current_company_url && this.progress >= popup.progress) {
				this.showPopup = true;
				this.text = popup.text;
				this.description = `Upsell. Запрос из курса: ${this.current_company_name}. Предлагаем купить: ${popup.course}`;
				show_popup('.j-upsell-popup');
			}
		});
	},

	methods: {
		sendUpsell() {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				url: this.events_path,
				method: "POST",
				data: {
					name: "request_upsell",
					description: this.description,
				}
			}).then((response) => {
				this.showPopup = false;
				this.showTwoPart = true;
				setTimeout(() => {
					show_popup('.j-upsell-two-part-popup');
				}, 500)
			}).catch((errors) => {
				console.error(errors.response.data);
			});
		}
	},
}
</script>
