{
	"buttons": {
		"all_presets": "Все подборки",
		"download_diploma": "Скачать диплом",
		"clear_filters": "Сбросить фильтры",
		"select_topic": "Выбрать тему курса",
		"presets": "Подборки курсов",
		"search": "Поиск",
		"clear": "Очистить",
		"signin": "Войдите",
		"start_playlist": "Запустить плейлист",
		"open_prescription": "Открыть назначение",
		"close_popup": "Закрыть попап",
		"open_course_page": "Подробнее",
		"close": "Закрыть",
		"will_learn": "Вы научитесь…",
		"show_completed": "Показать завершенные",
		"hide_completed": "Скрыть завершенные",
		"open_course": "Открыть курс",
		"choose_course": "Выбрать курс",
		"wishlist_all": "Все избранные курсы",
		"all_courses": "Все курсы",
		"all_on_topic": "Все курсы по этой теме",
		"all_recommended_programms": "Все рекомендуемые программы",
		"all_required_programms": "Все обязательные программы",
		"prescription_all": "Все назначенные программы",
		"show_completed": "Показать завершенные",
        "hide_completed": "Скрыть завершенные",
		"assign_all": "Назначить всем",
		"send": "Отправить",
        "cancel": "Отменить",
		"notify_all": "Напомнить всем",
		"add_file": "+ Добавить файл",
		"reset": "Сбросить",
		"collapse_menu": "Свернуть меню",
		"open_menu": "Открыть меню",
		"show_more": "Показать больше",
		"show_less": "Свернуть",
		"apply": "Применить",
		"save": "Сохранить"
	},
	"course_types": {
		"all": "Все типы",
		"audition": "Тренажер",
		"business_case": "Бизнес-кейс",
		"competences": "Тест",
		"dialog": "Диалоговый тренажёр",
		"educational_materials": "Учебный материал",
		"graduation_project": "Дипломный проект",
		"interview": "Интервью",
		"long": "Видеокурс",
		"longread": "Лонгрид",
		"materials": "Материал",
		"presentation": "Презентация",
		"quiz": "Квиз",
		"short": "Мини-курс",
		"summary": "Саммари книг",
		"test": "Тест",
		"playground": "Песочница",
		"practical_task": "Практическое задание"
	},
	"deadlines": {
		"late": "просрочено",
		"finished": "завершено",
		"current": "На прохождение остается",
		"continue": "Продолжить",
		"last_day": "завершается сегодня"
	},
	"placeholders": {
		"courses_search_placeholder": "Что хотите узнать сегодня?",
		"your_name": "Ваше имя",
		"phone": "Телефон",
		"your_answer": "Ваш ответ",
		"your_answer_homework": "Ваш ответ на домашнее задание"
	},
	"duration": {
		"h": " ч.",
		"min": " мин."
	},
	"messages": {
		"no_filters_message": "Ничего не найдено с такими фильтрами. Попробуйте сбросить их.",
		"mute_message": "Нажмите, чтобы включить звук.",
		"no_diplomas_message":"У вас пока нет завершенных курсов.",
		"start_your_learning_path": "Начните свое обучение здесь",
		"choose_description": "Выберите любой из сотни доступных курсов и тренажеров",
		"no_training": "Обучение не было назначено",
		"no_recommended_training": "Рекомендуемое обучение не было назначено",
		"megafon_1": "Вам не назначено обязательное обучение.",
		"megafon_2": "Вам доступны общие программы из библиотеки",
		"recommended_megafon_1": "Обучение в рамках развития по оценке 360 не было запланировано.",
		"recommended_megafon_2": "Вам доступны общие программы из библиотеки",
		"no_wished_plan": "В избранное ничего не добавлено.",
		"term_and_privacy": "Да, я принимаю условия <a href='https://eduson.tv/ru/policy-personal-data' target='_blank'>Соглашения</a> и&nbsp;даю согласие на обработку персональных данных в&nbsp;соответствии с&nbsp;<a href='https://www.eduson.tv/ru/privacy' target='_blank'>Политикой</a>",
		"mega_term_and_privacy": "Да, я принимаю условия <a href=' https://downloads.eduson.tv/materials/files/000/005/732/original/Term_mega.pdf' target='_blank'>Соглашения</a> и&nbsp;даю согласие на обработку персональных данных в&nbsp;соответствии с&nbsp;<a href='https://www.eduson.tv/ru/privacy' target='_blank'>Политикой</a>",
		"select_file": "Нажмите или перетащите файл в область загрузки",
		"answer_received": "Ваш ответ отправлен на проверку. Ожидайте ответа куратора",
		"homework_accepted": "Домашнее задание принято!",
		"homework_attempt_error": "Ваш ответ не принят. Попытайтесь ещё раз",
		"end_time": "До конца пробного периода осталось"
	},
	"days": "0 дней | {n} день | {n} дня | {n} дней",
	"minutes": "0 минут | {n} минута | {n} минуты | {n} минут",
	"hours": "0 часов | {n} час | {n} часа | {n} часов",
	"seconds": "0 секунд | {n} секунда | {n} секунды | {n} секунд",
	"today": "Сегодня",
	"left": "назад",
	"in": "в",
	"assignment": "Назначение",
	"filters": "Фильтры",
	"similar_courses": "Похожие курсы",
	"with_diploma": "С дипломом",
	"learning_plan": "План обучения",
	"learning_plan_completed_to": "План обучения выполнен на",
	"recommended_programms": "Рекомендуемые программы",
	"condidate_learning_plan": "Тестирование кандидата",
	"condidate_learning_plan_completed_to": "Тестирование кандидата выполнено на",
	"megafon_plan": "Обязательные к прохождению курсы",
	"megafon_plan_completed_to": "Обязательные к прохождению курсы освоены на",
	"recommended_megafon_plan": "Рекомендуемые курсы по программе развития по оценке 360",
	"presets": "Подборки курсов",
	"have_an_account": "Уже есть аккаунт?",
	"progress": "Прогресс",
	"timecodes": "Таймкоды",
	"where_to_begin": "С чего начать…",
	"wish_courses": "Избранные курсы",
	"full_only": "Только в полной версии",
	"started": "Начали",
	"sales_courses": {
        "cold_call": {
			"title": "Симулятор холодных звонков",
			"description": "<p class='sales-type-description'>Менеджеры по продажам слушают десятки записей телефонных звонков. Копируют лучшие техники и на чужих ошибках учатся проходить секретаря, отвечать на возражения, закрывать сделки.</p> <p class='sales-type-description'>Благодаря тренировкам они не растеряются и будут точно знать, что сказать, когда встретят раздраженного клиента или услышат возражение «Мне неинтересно».</p>"
		},
        "video_rehearsal": {
			"title": "Видео репетиции",
			"description": "<p class='sales-type-description'>Секрет успеха в продажах — практика. Запишите для своей команды серию видеороликов с типичными вопросами клиентов.</p> <p class='sales-type-description'>Проанализируйте видео-ответы менеджеров по продажам и дайте им обратную связь. <br>Видео-тренировки позволяют отрабатывать навыки в безопасных условиях: лучше ошибиться во время обучения, а не на встрече с клиентом.</p>"
		},
        "speech_recognition": {
			"title": "Тренажеры с распознаванием речи",
			"description": "<p class='sales-type-description'>Даже лучшие скрипты продаж не работают, если сотрудники их не знают.</p> <p class='sales-type-description'>Тренажеры с распознаванием речи — самый эффективный способ выучить скрипты наизусть. В тренажере сотрудники повторяют скрипт десятки раз, пока не доведут навык до автоматизма.</p> <p class='sales-type-description'>Технология распознавания речи не принимает неточных ответов.</p>"
		},
        "title": "Обучение продажам",
        "sub_title": "Интерактивные инструменты для обучения менеджеров <br> по продажам и операторов колл-центров"
	},
	"used_license": "Используется лицензий",
	"active_till": "Активны до",
	"contact_soon": "Мы скоро свяжемся с Вами",
	"employees_without_appointments": "Сотрудников без назначений",
	"avg_count_assignments": "У вас {avg_yours} назначения на сотрудника, среднее по вашей отрасли — {avg_total}",
	"overdue_appointments": "Просрочили свои назначения",
	"notification_send": "Напоминания отправлены.",
	"check_them": "Напомнить снова можно",
	"sending_notification": "Отправка напоминаний",
	"going_to_send": "Будет отправлено",
	"notifications": "0 напоминаний | {n} напоминание | {n} напоминания | {n} напоминаний",
	"license_popup": {
		"title": "Заявка на приобретение лицензий",
		"submit": "Отправить заявку",
		"message": "Менеджер свяжется с&nbsp;вами в&nbsp;рабочие часы, чтобы обсудить дополнительные лицензии для ваших сотрудников.",
		"invite": "Пригласить",
		"purchase_licenses": "Приобрести лицензии"
	},
	"homework": "Домашнее задание",
	"checking_homework": "Проверка домашних заданий",
	"homework_files": "Файлы для домашнего задания",
	"homework_statuses": {
		"sent": "Отправлено на проверку",
		"checking": "На проверке",
		"in_crosscheck": "На кроссчеке",
		"checked": "Проверено",
		"sending": "Отправляем",
		"success_done": "Успешно выполнено",
		"failed_done": "Провалено"
	},
	"hide_completed_homework_attempts": "Скрыть выполненные ДЗ",
	"curators_response": "Ответ куратора",
	"welcome_partners_title": "Лидирующие компании доверяют Eduson.tv <br>обучение своих сотрудников",
	"welcome_why_eduson_title": "Руководители отделов обучения и сотрудники компаний довольны работой с Eduson",
	"types_of_courses": "Типы курсов",
	"subjects": "Тематики",
	"sorting": {
		"new_first": "Сначала новые",
		"sorting_AZ": "По алфавиту от А до Я",
		"sorting_ZA": "По алфавиту от Я до А",
		"popular": "Популярные"
	},
	"found": "Найдено",
	"hide_completed_courses": "Скрыть завершенные курсы",
	"show_wished_courses": "Показать избранные курсы",
	"roles": {
		"super_admin": "Супер администратор",
		"courses_author": "Автор курсов",
		"curator": "Куратор",
		"sales_admin": "Администратор по продажам",
		"department_admin": "Администратор подразделения",
		"company_head": "Администратор компании",
		"user": "Пользователь"
	},
	"appointed_by": "Создал",
	"appointment_date": "Дата создания",
	"status": "Статус",
	"start": "Старт",
	"anonymous_survey": "Анонимный опрос",
	"popular_presets": "Популярные курсы"
}
