<template>
	<div class='common-banner'>
		<Carousel
			v-if="banners.length > 1"
			:itemsToShow="1"
			:itemsToScroll="1"
			:autoplay="5000"
			:wrapAround="true"
  			:pauseAutoplayOnHover="true"
			:transition="0"
			snapAlign="start"
		>
			<Slide v-for="(banner, index) in banners" :key="'top-banner-' + index">
				<a :href="banner.anchor ? '#' + banner.anchor : null"
					@click="handleClick(banner)"
					class="common-banner__link"
				>
					<img class="common-banner__image" :src="banner.image_url" alt="" />
				</a>
			</Slide>
			<template #addons>
				<Pagination />
			</template>
		</Carousel>

		<a v-else :href="banners[0].anchor ? '#' + banners[0].anchor : null"
			@click="handleClick(banners[0])"
			class="common-banner__link"
		>
			<img class="common-banner__image" :src="banners[0].image_url" alt="" />
		</a>
	</div>
</template>

<script>
import axios from "axios";
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export default {
	name: 'CommonBanner',

	components: {
		Carousel,
		Slide,
		Pagination
	},

	props: {
		current_company: {
			type: String,
			required: true
		},
		events_url: {
			type: String,
			required: true
		},
		current_user_id: {
			type: Number,
			required: false
		},
		banners: {
			type: Array,
			required: true
		},
	},

	methods: {
		handleClick(banner) {
			if (banner.click_event_name) {
				this.sendEvent(banner);
			} else if (banner.redirect_url) {
				window.location.href = banner.redirect_url;
			}
		},

		sendEvent(banner) {
			axios.request({
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": document
						.querySelector('meta[name="csrf-token"]')
						.getAttribute("content"),
				},
				url: banner.events_url,
				method: "POST",
				data: {
					name: banner.click_event_name,
					description: "Запрос из курса: " + this.current_company,
					user_id: this.current_user_id,
					eventable_type: "Company",
					eventable_id: this.current_company.id,
					redirect_url: banner.redirect_url
				}
			}).then((response) => {
				window.location.href = banner.redirect_url;
			})
		}
	}
}
</script>

<style>
.common-banner {
	position: relative;
}

.common-banner__link {
	display: block;
	width: 100%;
	border-radius: 12px;
	overflow: hidden;
}

.common-banner__image {
	display: block;
	width: 100%;
}

.common-banner .carousel__pagination {
	--vc-pgn-background-color: hsla(0, 0%, 100%, 0.5);
	--vc-pgn-active-color: hsla(0, 0%, 100%, 1);
	--vc-pgn-border-radius: 2px;
	--vc-pgn-width: 4px;

	position: absolute;
	right: 12px;
	bottom: 12px;
	border-radius: 6px;
	background-color: hsla(0, 0%, 0%, 0.2);
}
</style>
